exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-estudios-js": () => import("./../../../src/pages/estudios.js" /* webpackChunkName: "component---src-pages-estudios-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-hostal-js": () => import("./../../../src/pages/hostal.js" /* webpackChunkName: "component---src-pages-hostal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacion-que-cura-js": () => import("./../../../src/pages/informacion-que-cura.js" /* webpackChunkName: "component---src-pages-informacion-que-cura-js" */),
  "component---src-pages-talleristas-js": () => import("./../../../src/pages/talleristas.js" /* webpackChunkName: "component---src-pages-talleristas-js" */),
  "component---src-templates-room-jsx": () => import("./../../../src/templates/room.jsx" /* webpackChunkName: "component---src-templates-room-jsx" */)
}

